import Dexie from 'dexie'

// login method
export async function login(phone, drCode) {
  const apiHost = `${process.env.REACT_APP_API_URL}`
  const postData = {
    userid: phone,
    drCode,
  }

  function verifyDrCode() {
    return new Promise(resolve => {
      fetch(`${apiHost}/api/users/verifyDrCode`, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then(response => {
          // console.log(response)
          if (response.ok) {
            return response.json()
          }
          const error = new Error(response.statusText)
          error.response = response
          throw error
        })
        .then(data => {
          console.log('Success:', data)

          // 儲存 IndexedDB
          console.log('open IndexedDB')
          const dbName = 'FunncodeDatabase'
          const db = new Dexie(dbName)
          db.version(1).stores({ account: '++id' })
          db.account.add({ id: 'user', value: data })

          resolve(data)
        })
        .catch(error => {
          console.error('Error:', error)
          resolve(false)
        })
    })
  }
  return verifyDrCode()
}

export async function currentAccount() {
  const apiHost = `${process.env.REACT_APP_API_URL}`
  const postData = {
    token: null,
  }

  // 讀取 IndexedDB
  console.log('open IndexedDB')
  const dbName = 'FunncodeDatabase'
  const db = new Dexie(dbName)
  db.version(1).stores({ account: '++id' })
  const accountData = await db.account.get('user')

  if (!accountData) {
    console.log('尚未登入')
    return false
  }

  console.log('accountData', accountData)
  const { token: localToken } = accountData.value
  postData.token = localToken

  function getCurrentUser() {
    return new Promise(resolve => {
      fetch(`${apiHost}/api/users/getAccountInfo`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${postData.token}`,
        }),
      })
        .then(response => {
          console.log(response)
          if (response.ok) {
            return response.json()
          }
          const error = new Error(response.statusText)
          error.response = response
          throw error
        })
        .then(data => {
          console.log('Success:', data)
          data.token = localToken // add
          resolve(data)
        })
        .catch(error => {
          console.error('Error:', error)
          // eslint-disable-next-line no-alert
          alert('請重新登入')
          logout()
          // window.location.href = '/admin/'
          resolve(false)
        })
    })
  }
  return getCurrentUser()
}

export async function logout() {
  // 刪除 IndexedDB
  console.log('open IndexedDB')
  const dbName = 'FunncodeDatabase'
  const db = new Dexie(dbName)
  db.version(1).stores({ account: '++id' })
  await db.account.delete('user')
  console.log('accountData deleted')

  return true
}
