import React from 'react'
import { connect } from 'react-redux'
// import { Button } from 'antd'
// import { LogoutOutlined } from '@ant-design/icons'
// import { FormattedMessage } from 'react-intl'
// import HomeMenu from './HomeMenu'
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch'
// import BitcoinPrice from './BitcoinPrice'
// import ProfileMenu from './ProfileMenu'
// import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'

@connect(({ user, settings }) => ({ user, settings }))
class TopBar extends React.Component {
  constructor(props) {
    super(props)
    this.title = props.title
  }

  // 登出函式
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { user, settings } = this.props
    return (
      <div className={styles.topbar}>
        {/* 標題 */}
        <div className={styles.title}>{settings.page}</div>

        {/* 醫師查看驗證碼 */}
        <div className={styles.code}>
          {user.drCode}
          {/* <i className={`bi bi-search ${styles.icon}`} /> */}
          <img
            src="/resources/images/icons/number.png"
            alt=""
            height="24"
            className={styles.icon}
          />
        </div>

        {/* 身高 */}
        <div className={styles.height}>
          {user.height}cm
          <img src="/resources/images/icons/tall.png" alt="" height="24" className={styles.icon} />
        </div>

        {/* 體重 */}
        <div className={styles.weight}>
          {user.weight}kg
          <img
            src="/resources/images/icons/weight.png"
            alt=""
            height="24"
            className={styles.icon}
          />
        </div>

        {/* 姓名 */}
        <div className={styles.name}>
          {user.name}
          {/* <i className={`bi bi-person-circle ${styles.icon}`} /> */}
          <img
            src="/resources/images/icons/bigperson.png"
            alt=""
            height="24"
            className={styles.icon}
          />
        </div>

        {/* 登出按鈕 */}
        <div
          className={styles.logout}
          onClick={this.logout}
          onKeyDown={this.logout}
          aria-hidden="true"
        >
          登出
          {/* <i className={`bi bi-box-arrow-left ${styles.icon}`} /> */}
          <img
            src="/resources/images/icons/logout.png"
            alt=""
            height="24"
            className={styles.icon}
          />
        </div>
      </div>
    )
  }
}

export default TopBar
