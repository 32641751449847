export async function getLeftMenuData() {
  return [
    {
      title: '用藥時刻表',
      key: 'treatmentPlan',
      url: '/treatmentPlan',
      icon: 'bi bi-list-check',
    },
    {
      title: 'PD日記',
      key: 'symptomRecord',
      url: '/symptomRecord',
      icon: 'bi bi-pencil-square',
    },
    {
      title: '病史',
      key: 'symptomTracking',
      url: '/symptomTracking',
      icon: 'bi bi-calendar-date',
    },
    // {
    //   title: 'Default Pages',
    //   key: 'defaultPages',
    //   icon: 'icmn icmn-file-text',
    //   children: [
    //     {
    //       key: 'loginAlpha',
    //       title: 'Login Alpha',
    //       url: '/pages/login-alpha',
    //       pro: true,
    //     },
    //     {
    //       key: 'loginBeta',
    //       title: 'Login Beta',
    //       url: '/pages/login-beta',
    //       pro: true,
    //     },
    //     {
    //       key: 'register',
    //       title: 'Register',
    //       url: '/pages/register',
    //       pro: true,
    //     },
    //     {
    //       key: 'lockscreen',
    //       title: 'Lockscreen',
    //       url: '/pages/lockscreen',
    //       pro: true,
    //     },
    //     {
    //       key: 'pricingTable',
    //       title: 'Pricing Tables',
    //       url: '/pages/pricing-table',
    //       pro: true,
    //     },
    //     {
    //       key: 'invoice',
    //       title: 'Invoice',
    //       url: '/pages/invoice',
    //       pro: true,
    //     },
    //   ],
    // },
  ]
}
export async function getTopMenuData() {
  return []
}
